.Box {
  --borderSize: 3px;
  box-sizing: border-box;
  background: var(--red);
  padding: 3px;
  border: var(--borderSize) solid var(--red);
  width: var(--box-size);
  height: var(--box-size);
  display: grid;
  grid-template:
    '. . codePoint' auto
    '. . .' 1fr
    'number . timing' auto/ auto 1fr auto;
  position: relative;
  .value {
    grid-column: 1/4;
    grid-row: 1/4;
    font-size: calc(var(--box-size) / 1.5);
    font-weight: bold;
    text-align: center;
    line-height: calc(var(--box-size) - 30px);
  }
  .number {
    grid-area: number;
    color: lighten(#111, 20%);
    text-align: left;
  }
  .largeNumber {
    position: absolute;
    top: calc(0px - var(--borderSize));
    right: calc(0px - var(--borderSize));
    left: calc(0px - var(--borderSize));
    bottom: calc(0px - var(--borderSize));
    overflow: hidden;
    .inner {
      position: absolute;
      top: calc(-3 / 10 * var(--box-size));
      left: calc(-1 / 10 * var(--box-size));
      right: 0;
      font-weight: bold;
      font-size: calc(var(--box-size) - 30px);
      color: transparent;
      text-shadow: 2px 2px 3px rgba(255, 65, 54, 0.4);
      text-align: left;
      letter-spacing: calc(var(--box-size) / -12);
      background: var(--black);
      background-clip: text;
      line-height: var(--box-size);
    }
  }
  .timing {
    grid-area: timing;
    text-align: right;
    color: lighten(#111, 30%);
  }
  .codePoint {
    grid-area: codePoint;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
    gap: 5px;
    &,
    a {
      color: lighten(#111, 30%);
    }
    .upperCase {
      font-size: 30px;
      font-size: 18px;
      line-height: 22px;
    }
  }
  &.active {
    border-color: white;
  }
  @media screen and (max-width: 1005px) {
    > :not(.value):not(.number):not(.largeNumber) {
      display: none;
    }
  }
}
