@import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400,700&display=swap');

html,
body {
  height: 100%;
  margin: 0;
  #root {
    height: 100%;
  }
}

:root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--black);

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --navy: #001f3f;
  --blue: #0074d9;
  --aqua: #7fdbff;
  --teal: #39cccc;
  --olive: #3d9970;
  --green: #2ecc40;
  --lime: #01ff70;
  --yellow: #ffdc00;
  --orange: #ff851b;
  --red: #ff4136;
  --maroon: #85144b;
  --fuchsia: #f012be;
  --purple: #b10dc9;
  --black: #111111;
  --gray: #aaaaaa;
  --silver: #dddddd;
  --white: #ffffff;

  --gap: 15px;
  @media screen and (max-width: 1005px) {
    --gap: calc(0.015 * 100vw);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
