.App {
  background-color: var(--navy);
  padding: var(--gap);
  min-height: 100%;
  box-sizing: border-box;
  .subApps {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    .subApp {
      display: grid;
      grid-template-columns: minmax(auto, var(--max-width));
      justify-content: center;
    }
  }
}
