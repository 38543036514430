.SubApp {
  background-color: var(--navy);
  padding: var(--gap);
  min-height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(auto, var(--max-width));
  justify-content: center;
  align-items: start;
}
