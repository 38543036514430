@import url(https://fonts.googleapis.com/css?family=Roboto+Mono:400,700&display=swap);
html,
body {
  height: 100%;
  margin: 0; }
  html #root,
  body #root {
    height: 100%; }

:root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  color: #111111;
  color: var(--black);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --navy: #001f3f;
  --blue: #0074d9;
  --aqua: #7fdbff;
  --teal: #39cccc;
  --olive: #3d9970;
  --green: #2ecc40;
  --lime: #01ff70;
  --yellow: #ffdc00;
  --orange: #ff851b;
  --red: #ff4136;
  --maroon: #85144b;
  --fuchsia: #f012be;
  --purple: #b10dc9;
  --black: #111111;
  --gray: #aaaaaa;
  --silver: #dddddd;
  --white: #ffffff;
  --gap: 15px; }
  @media screen and (max-width: 1005px) {
    :root {
      --gap: calc(0.015 * 100vw); } }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.Box {
  --borderSize: 3px;
  box-sizing: border-box;
  background: var(--red);
  padding: 3px;
  border: var(--borderSize) solid var(--red);
  width: var(--box-size);
  height: var(--box-size);
  display: grid;
  grid-template: '. . codePoint' auto '. . .' 1fr 'number . timing' auto/ auto 1fr auto;
  position: relative; }
  .Box .value {
    grid-column: 1/4;
    grid-row: 1/4;
    font-size: calc(var(--box-size) / 1.5);
    font-weight: bold;
    text-align: center;
    line-height: calc(var(--box-size) - 30px); }
  .Box .number {
    grid-area: number;
    color: #444444;
    text-align: left; }
  .Box .largeNumber {
    position: absolute;
    top: calc(0px - var(--borderSize));
    right: calc(0px - var(--borderSize));
    left: calc(0px - var(--borderSize));
    bottom: calc(0px - var(--borderSize));
    overflow: hidden; }
    .Box .largeNumber .inner {
      position: absolute;
      top: calc(-3 / 10 * var(--box-size));
      left: calc(-1 / 10 * var(--box-size));
      right: 0;
      font-weight: bold;
      font-size: calc(var(--box-size) - 30px);
      color: transparent;
      text-shadow: 2px 2px 3px rgba(255, 65, 54, 0.4);
      text-align: left;
      letter-spacing: calc(var(--box-size) / -12);
      background: var(--black);
      -webkit-background-clip: text;
              background-clip: text;
      line-height: var(--box-size); }
  .Box .timing {
    grid-area: timing;
    text-align: right;
    color: #5e5e5e; }
  .Box .codePoint {
    grid-area: codePoint;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
    grid-gap: 5px;
    gap: 5px; }
    .Box .codePoint,
    .Box .codePoint a {
      color: #5e5e5e; }
    .Box .codePoint .upperCase {
      font-size: 30px;
      font-size: 18px;
      line-height: 22px; }
  .Box.active {
    border-color: white; }
  @media screen and (max-width: 1005px) {
    .Box > :not(.value):not(.number):not(.largeNumber) {
      display: none; } }

.Boxes {
  display: grid;
  grid-gap: var(--gap);
  gap: var(--gap);
  grid-template-columns: repeat(6, var(--box-size));
  justify-content: center;
  grid-auto-flow: row dense;
  --box-size: 150px;
  font-family: 'Roboto Mono', monospace; }
  @media screen and (max-width: 1005px) {
    .Boxes {
      --box-size: calc(0.15 * 100vw);
      --gap: calc(0.014 * 100vw); } }

.App {
  background-color: var(--navy);
  padding: var(--gap);
  min-height: 100%;
  box-sizing: border-box; }
  .App .subApps {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 15px;
    gap: 15px; }
    .App .subApps .subApp {
      display: grid;
      grid-template-columns: minmax(auto, var(--max-width));
      justify-content: center; }

.SubApp {
  background-color: var(--navy);
  padding: var(--gap);
  min-height: 100%;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: minmax(auto, var(--max-width));
  justify-content: center;
  align-items: start; }

