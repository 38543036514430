.Box {
  --borderSize: 3px;
  box-sizing: border-box;
  background: var(--red);
  padding: 3px;
  border: var(--borderSize) solid var(--red);
  width: var(--box-size);
  height: var(--box-size);
  display: grid;
  grid-template: '. . codePoint' auto '. . .' 1fr 'number . timing' auto/ auto 1fr auto;
  position: relative; }
  .Box .value {
    grid-column: 1/4;
    grid-row: 1/4;
    font-size: calc(var(--box-size) / 1.5);
    font-weight: bold;
    text-align: center;
    line-height: calc(var(--box-size) - 30px); }
  .Box .number {
    grid-area: number;
    color: #444444;
    text-align: left; }
  .Box .largeNumber {
    position: absolute;
    top: calc(0px - var(--borderSize));
    right: calc(0px - var(--borderSize));
    left: calc(0px - var(--borderSize));
    bottom: calc(0px - var(--borderSize));
    overflow: hidden; }
    .Box .largeNumber .inner {
      position: absolute;
      top: calc(-3 / 10 * var(--box-size));
      left: calc(-1 / 10 * var(--box-size));
      right: 0;
      font-weight: bold;
      font-size: calc(var(--box-size) - 30px);
      color: transparent;
      text-shadow: 2px 2px 3px rgba(255, 65, 54, 0.4);
      text-align: left;
      letter-spacing: calc(var(--box-size) / -12);
      background: var(--black);
      background-clip: text;
      line-height: var(--box-size); }
  .Box .timing {
    grid-area: timing;
    text-align: right;
    color: #5e5e5e; }
  .Box .codePoint {
    grid-area: codePoint;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: end;
    gap: 5px; }
    .Box .codePoint,
    .Box .codePoint a {
      color: #5e5e5e; }
    .Box .codePoint .upperCase {
      font-size: 30px;
      font-size: 18px;
      line-height: 22px; }
  .Box.active {
    border-color: white; }
  @media screen and (max-width: 1005px) {
    .Box > :not(.value):not(.number):not(.largeNumber) {
      display: none; } }
