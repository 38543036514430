$boxWidth: 150px;

.Boxes {
  display: grid;
  gap: var(--gap);
  grid-template-columns: repeat(6, var(--box-size));
  justify-content: center;
  grid-auto-flow: row dense;
  --box-size: #{$boxWidth};
  // $boxWidth * 6 + 5 * 15 + 2 * 15
  @media screen and (max-width: 1005px) {
    --box-size: calc(0.15 * 100vw);
    --gap: calc(0.014 * 100vw);
  }
  font-family: 'Roboto Mono', monospace;
}
